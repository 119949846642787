import React from 'react';
import Skeleton from 'react-loading-skeleton';
import AvatarLoader from './AvatarLoader';
import Image from 'react-shimmer';

const DoctorItem = ({ doctor, appointment_locale }) => {
  const renderLocations = () => {
    if (!doctor.practic_locations) {
      return <Skeleton width={200} />;
    }

    return doctor.practic_locations.map((location, index) => (
      <span key={index} className="text-success text-bold">
        <i className="fas fa-map-pin"></i> {location || <Skeleton width={200} />}
      </span>
    ));
  };

  const renderDoctorInfo = () => (
    <div className="doctor-content">
      <h3>
        <a href={`/doctors/${doctor.slug}`} className="details-btn">
          {doctor.full_name || <Skeleton width={200} />}
        </a>
      </h3>
      <span>
        <i className="fas fa-stethoscope"></i> {doctor.speciality || <Skeleton width={200} />}
      </span>
      {renderLocations()}
    </div>
  );

  const renderAppointmentButton = () => {
    if (doctor.available_for_appointment) {
      return (
        <div className="submit-btn">
          <a href={`/doctors/${doctor.slug}/appointments/new`} className="btn-book btn-primary">
            {appointment_locale}
          </a>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="col-lg-4 col-md-6 col-sm-6">
      <div className="single-doctor-box card-full-height">
        <div className="doctor-image">
          <Image
            src={doctor.avatar.thumb.url}
            fallback={<Skeleton circle={true} height={120} width={120} />}
            errorFallback={() => <AvatarLoader />}
            NativeImgProps={{ alt: doctor.full_name }}
          />
          <a href={`/doctors/${doctor.slug}`} className="details-btn">
            <i className="fas fa-plus" />
          </a>
        </div>
        {renderDoctorInfo()}
        {renderAppointmentButton()}
      </div>
    </div>
  );
};

export default DoctorItem;
