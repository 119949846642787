
import React, { lazy, Suspense } from 'react';
import Skeleton from 'react-loading-skeleton';
import filterObserver from "../observer/FilterService"
import { objToQueryString } from '../plugins/url-utils'
import queryString from 'query-string'


const renderLoader = () => <Skeleton height={20} width={60} />;
const DoctorItem = lazy(() => import('./DoctorItem'));
const BtnLoadMore = lazy(() => import('./BtnLoadMore'));

// const loadDoctors = (props) =>
//     fetch(`http://localhost:7000/api/v1/public/doctors`)
//       .then(res => (res.ok ? res : Promise.reject(res)))
//       .then(res => res.json())

export default class DoctorsList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      doctorsLength: 6,
      loading: false,
      error: false,
      doctors: null,
      nextPage: null 
    };
    // to allow the corresponding method to access 'this' variable
    this.onFilterUpdate = this.onFilterUpdate.bind(this);
    this.fetchDoctors = this.fetchDoctors.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  componentDidMount() {
    // subscribe to any filter changes
    filterObserver.attach(this.onFilterUpdate);
    var parsed = queryString.parse(location.search)
    // console.log('parsed url', parsed)
    // if (location.search) {
    //   console.log('location search url', location.search)
    // }
    this.fetchDoctors(queryString.stringify(parsed), true);
  }

  componentWillUnmount() {
    // unsubscribe filter changes
    filterObserver.detach(this.onFilterUpdate);
  }

  onFilterUpdate(data) {
    let filter;
    // check is there any value exists,
    // otherwise remove it from this.state.filter
    // if (Object.values(data).filter(val => val.length).length) {
    //   // there is any value inside the incoming filter
    //   // update/replace the filter
    //   filter = { ...this.state.filter, ...data };
    // } else {
    //   // no values given
    //   // remove corresponding filter
    //   for (const key in this.state.filter) {
    //     if (this.state.filter.hasOwnProperty(key) && data.hasOwnProperty(key)) {
    //       delete this.state.filter[key];
    //     }
    //   }
    //   filter = this.state.filter;
    // }
    filter = { ...this.state.filter, ...data };
    // update the state
    this.setState({ filter });
    // console.log("onFilterUpdated", filter);
    const params = objToQueryString(filter);
    this.fetchDoctors(params, true);
  }

  generateSignatureHeader(){
    const timeStamp = new Date().getTime()
    const appKey = `Hrmn_haASjh76asjdh||HrmnAID||${timeStamp}`
    const signature = btoa(appKey)
    // console.log('params', queryString);
    // console.log('signature is', signature);
    // console.log('timeStamp is', timeStamp);
    let headers = {"Content-Type": "application/json"};
    headers["Signature"] = signature;
    headers["timeStamp"] = timeStamp;
  }

  fetchDoctors(queryString, replaceExisting) {
    const timeStamp = new Date().getTime()
    const appKey = `Hrmn_haASjh76asjdh${timeStamp}||HrmnAID||`
    const signature = btoa(appKey)
    // console.log('params', queryString);
    let headers = {"Content-Type": "application/json"};
    headers["Signature"] = signature;
    headers["Timestamp"] = timeStamp;
    // make `this` to be always callbale inside different instance
    const _this = this;
    // set loading to true while fetching the data
    this.setState({ loading: true}, () => {
      const url = `${this.props.url}${queryString ? '?' + queryString : ''}`;
      // console.log('fetching doctors:', url);
      fetch(url, {headers})
        .then(res => (res && res.status === 200 && res.ok ? res.json() : Promise.reject(res)))
        .then(data => {
          // console.log("retrieved", data);
          let doctors;
          // replace or addition
          if (replaceExisting) {
            doctors = data.data;
          } else {
            doctors = [..._this.state.doctors, ...data.data]
            // console.log('Doctors if replaceExisting false is ', doctors)
          }
          // update data
          // setTimeout(() => {
          //   _this.setState({
          //     loading: false,
          //     doctors: doctors,
          //     nextPage: data.pagination.next
          //   });
          // }, 1200);
          _this.setState({
            loading: false,
            doctors: doctors,
            nextPage: data.pagination.next
          });
        }).catch(res => {
          // error occurred
          console.warn('fetching error occurred', res);
          _this.setState({
            error: res
          });
        }).finally(() => {
          // request finished, set loading to false
          _this.setState({ loading: false })
        });
    });
  }

  generateContent(isLoading, doctors, error, doctorsLength) {
    if (error) {
      return (
        <div>
          <div className="d-lg-none">
            <div className="alert alert-success text-center">
              Mohon maaf atas ketidaknyamanannya, sistem pemesanan janji temu RS Hermina sedang dalam masa perbaikan🙏
              <br/>
              Silakan lakukan pemesanan janji temu dengan menghubungi call center di 1500-488 atau download aplikasi Halo Hermina.
            </div>
          </div>
          <div className="d-none d-lg-block">
            <div className="success-box">
              <div className="face2">
                <div className="eye"></div>
                <div className="eye right-posistion"></div>
                <div className="mouth sad"></div>
              </div>
              <div className="shadow-card move"></div>
              <div className="message">
                <h1 className="alert">Mohon maaf atas ketidaknyamanannya, sistem pemesanan janji temu RS Hermina sedang dalam masa perbaikan🙏</h1>
                <p className="text-white">Silakan lakukan pemesanan janji temu dengan menghubungi call center di 1500-488 atau download aplikasi Halo Hermina. </p>
              </div>
            </div>
          </div>
        </div>
      )
    } else if (doctors) {
      if (doctors.length) {
        return (
          <div>
            <div className="row">
              {doctors.map(doctor => (
                <Suspense key={doctor.id} fallback={renderLoader()}>
                  <DoctorItem key={doctor.id} doctor={doctor.attributes} appointment_url={this.props.appointment_url} appointment_locale={this.props.appointment_locale} />
                </Suspense>
              ))}
            </div>
            { isLoading && this.renderLoading(doctorsLength)}
          </div>
        );
      } else {
        return (<h3 className="text-center">{this.props.not_found}</h3>);
      }
    } else {
      return this.renderLoading(doctorsLength);
    }
  }

  renderLoading(totalDoctors){
    // console.log('laoding here', totalDoctors)
    return(
      <div className="row">
        {Array(totalDoctors)
          .fill()
          .map((item, index) => (
            <div key={index} className=" col-lg-4 col-md-6 col-sm-6">
              <div className="single-doctor-box card-full-height">
                <div className="doctor-image">
                  <Skeleton circle={true} height={120} width={120} />
                </div>
                <div className="doctor-content">
                  <h3 className="text-center">
                    <Skeleton height={15} />
                  </h3>
                  <span className="text-center"> <Skeleton height={12} /> </span>
                  <span className="text-center"> <Skeleton height={12} /> </span>
                </div>
                <div className="submit-btn">
                  <Skeleton height={20} />
                </div>
              </div>
            </div>
          ))
        }
      </div>
    )
  }

  handleLoadMore() {
    let params = {
      page: this.state.nextPage
    };

    if (Object.keys(this.state.filter).length) {
      params = { ...params, ...this.state.filter };
    }
    if (location.search) {
      // console.log('location search url', location.search)
      var parsed = queryString.parse(location.search)
      params = { ...params, ...parsed };
    }

    params = objToQueryString(params);
    this.fetchDoctors(params, false);
  }

  render(){
    const { loading, doctors, error, nextPage, doctorsLength } = this.state;
    return (
      <div className="doctor-wrapper">
        {this.generateContent(loading, doctors, error, doctorsLength)}
        {nextPage && 
          <div id="div_next_link">
            <div className="load-more text-center">
            <Suspense fallback={renderLoader()}>
              <BtnLoadMore onClick={this.handleLoadMore}></BtnLoadMore>
            </Suspense>
            </div>
          </div>
        }
      </div>
    )
  }
}
