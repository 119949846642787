class FilterService {
  observers = [];

  attach(observer) {
    this.observers.push(observer);
  }

  detach(observer) {
    this.observers = this.observers.filter(obsrv => obsrv !== observer);
  }

  notify(data) {
    this.observers.forEach(observer => observer(data));
  }
}

const filterObserver = new FilterService();

export default filterObserver;
